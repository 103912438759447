<template>
  <div class="page_content page_content_store">
    <div class="content_header d-flex align-items-center">
      <span>門市管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between">
          <div class="search_section flex-fill">
            <div class="form-group d-flex align-items-center">
              <label for="system">系統</label>
              <select
                id="system"
                v-model="search.system"
                v-validate="'required'"
                name="system"
                class="form-control search_brand"
                :class="{'is-invalid': errors.has('search.system')}"
                data-vv-scope="search"
                @change="get_store_category()"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  系統
                </option>
                <option
                  v-for="(item, index) in system"
                  :key="index"
                  :value="item.systemCode"
                >
                  {{ item.systemName }}
                </option>
              </select>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="category">分類</label>
              <select
                id="category"
                v-model="search.category_code"
                v-validate="'required'"
                name="category"
                class="form-control search_brand"
                :class="{'is-invalid': errors.has('search.category')}"
                data-vv-scope="search"
                @change="get_store_list()"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  分類
                </option>
                <option
                  v-for="(item, index) in filter_store_category"
                  :key="index"
                  :value="item.categorySubCode"
                >
                  {{ item.categorySubName }}
                </option>
              </select>
            </div>
            <a
              v-if="!edit_mode"
              href="#"
              class="search_btn d-flex align-items-center justify-content-center rounded px-2"
              @click.prevent="init_store(), edit_mode = true, edit_section_new = true"
            >
              <span>建立門市</span>
            </a>
            <a
              v-else
              href="#"
              class="search_btn d-flex align-items-center justify-content-center ml-auto rounded"
              @click.prevent="edit_mode = false, edit_section_new = false, init_validate();"
            >
              <span>返回</span>
            </a>
            <div
              v-if="!edit_mode && store_list.length !== 0"
              class="d-flex ml-auto"
            >
              <div class="form-group d-flex align-items-center m-0">
                <select
                  v-model="pagination.filter_page_size"
                  class="form-control"
                >
                  <template v-if="Math.ceil(store_list.length / 10) > 10">
                    <option
                      v-for="(item, index) in 9"
                      :key="index"
                      :value="(item + 1) * 10"
                    >
                      {{ (item + 1) * 10 }}
                    </option>
                  </template>
                  <template v-else>
                    <option
                      v-for="(item, index) in Math.ceil(store_list.length / 10)"
                      :key="index"
                      :value="(item + 1) * 10"
                    >
                      {{ (item + 1) * 10 }}
                    </option>
                  </template>
                  <!-- <option
                    v-for="(item, index) in Math.ceil(store_list.length / 10)"
                    :key="index"
                    :value="item * 10"
                  >
                    {{ item * 10 }}
                  </option> -->
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!edit_mode"
          class="table-responsive"
        >
          <table
            class="table table-borderless"
          >
            <thead class="thead-light">
              <tr>
                <th
                  scope="col"
                >
                  門市代碼
                </th>
                <th
                  scope="col"
                >
                  門市名稱
                </th>
                <th
                  scope="col"
                >
                  地址
                </th>
                <th
                  scope="col"
                >
                  時段/電話
                </th>
              </tr>
            </thead>
            <draggable
              v-model="filter_Data"
              tag="tbody"
              @change="update_order"
            >
              <tr
                v-for="(store_detail, index) in filter_Data"
                :key="index"
              >
                <td class="">
                  <div>
                    {{ store_detail.storeCode }}
                  </div>
                  <a
                    v-if="!store_detail.status"
                    href="#"
                    class="d-inline-block btn btn_outline_danger rounded-sm px-2"
                    @click.prevent="delete_store(store_detail.storeCode)"
                  >刪除</a>
                </td>
                <td class="">
                  <a
                    href="#"
                    class="text-color_primary"
                    @click.prevent="get_store_detail(store_detail.storeCode)"
                  >
                    {{ store_detail.storeName }}
                  </a>
                </td>
                <td class="">
                  <div>{{ store_detail.address }}</div>
                </td>
                <td class="">
                  <div>{{ store_detail.officeHour }}</div>
                  <div>{{ store_detail.officeHourPeriod }}</div>
                  <div>{{ store_detail.telnum }}</div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <ul
          v-if="filter_Data.length > 0 && !edit_mode"
          class="pagination"
        >
          <li
            class="pagination-item"
            :class="{ 'pagination-item-disabled': !pagination.has_pre }"
          >
            <a
              href="#"
              class="pagination-link"
              aria-label="Previous"
              @click.prevent="change_page(pagination.current_page - 1)"
            >
              <i class="icon-chevron-thin-left" />
            </a>
          </li>
          <li
            v-for="(page, index) in pagination.filter_range_page"
            :key="index"
            class="pagination-item"
          >
            <a
              href="#"
              class="pagination-link"
              :class="{ 'pagination-link-active': pagination.current_page === page.index }"
              @click.prevent="change_page(page.index)"
            >{{ page.index }}</a>
          </li>
          <li
            class="pagination-item"
            :class="{ 'pagination-item-disabled': !pagination.has_next }"
          >
            <a
              href="#"
              class="pagination-link"
              aria-label="Next"
              @click.prevent="change_page(pagination.current_page + 1)"
            >
              <i class="icon-chevron-thin-right" />
            </a>
          </li>
        </ul>
        <div
          v-if="edit_mode"
          class="edit_section"
        >
          <div class="section_header">
            <span v-show="edit_section_new">建立門市</span>
            <span v-show="!edit_section_new">編輯門市</span>
          </div>
          <div class="section_body container-fluid">
            <div
              v-if="temp_search.length !== 0 && temp_category_data.length === temp_search.length"
              class="row"
            >
              <div
                v-for="(item, index) in temp_category_data"
                :key="index"
                class="col-12 col-md-6 col-xl-4"
              >
                <div
                  class="search_section d-block"
                >
                  <div class="search_section_header d-flex justify-content-between align-items-center text-color_primary rounded-top">
                    <span>歸屬分類 {{ index + 1 }}</span>
                    <a
                      v-if="temp_category_data.length > 1"
                      href="#"
                      class="text-danger"
                      @click.prevent="delete_category(index)"
                    >
                      <i class="icon-cross align-middle" />
                    </a>
                  </div>
                  <div class="search_section_body">
                    <div class="form-group d-flex align-items-center">
                      <label :for="`temp_search_system_${index}`">系統</label>
                      <select
                        :id="`temp_search_system_${index}`"
                        v-model="temp_search[index].system"
                        v-validate="'required'"
                        class="form-control search_brand"
                        :class="{'is-invalid': errors.has(`detail.系統 ${index + 1}`)}"
                        data-vv-scope="detail"
                        :name="`系統 ${index + 1}`"
                        @change="get_temp_category($event.target.value, index)"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          系統
                        </option>
                        <option
                          v-for="(system, i) in system"
                          :key="i"
                          :value="system.systemCode"
                        >
                          {{ system.systemName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label :for="`temp_search_categorySubCode_${index}`">分類</label>
                      <select
                        :id="`temp_search_categorySubCode_${index}`"
                        v-model="temp_search[index].categorySubCode"
                        v-validate="'required'"
                        class="form-control"
                        :name="`分類 ${index + 1}`"
                        :class="{'is-invalid': errors.has(`detail.分類 ${index + 1}`)}"
                        data-vv-scope="detail"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          分類
                        </option>
                        <option
                          v-for="category in item.category"
                          :key="category.categorySubCode"
                          :value="category.categorySubCode"
                        >
                          {{ category.categorySubName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label :for="`temp_search_categoryType_${index}`">類型</label>
                      <select
                        :id="`temp_search_categoryType_${index}`"
                        v-model="temp_search[index].categoryType"
                        v-validate="'required'"
                        class="form-control"
                        :name="`類型 ${index + 1}`"
                        :class="{'is-invalid': errors.has(`detail.類型 ${index + 1}`)}"
                        data-vv-scope="detail"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          類型
                        </option>
                        <option
                          v-for="storeType in option.storeType"
                          :key="storeType.name"
                          :value="storeType.name"
                        >
                          {{ storeType.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label :for="`temp_search_dtStart_${index}`">起始日期</label>
                      <input
                        :id="`temp_search_dtStart_${index}`"
                        v-model="temp_search[index].dtStart"
                        v-validate="'required'"
                        type="date"
                        :name="`起始日期 ${index + 1}`"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has(`detail.起始日期 ${index + 1}`)}"
                        data-vv-scope="detail"
                        placeholder="起始日期"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label :for="`temp_search_dtEnd_${index}`">結束日期</label>
                      <input
                        :id="`temp_search_dtEnd_${index}`"
                        v-model="temp_search[index].dtEnd"
                        v-validate="'required'"
                        type="date"
                        :name="`結束日期 ${index + 1}`"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has(`detail.結束日期 ${index + 1}`)}"
                        data-vv-scope="detail"
                        placeholder="結束日期"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>是否為臨時櫃</label>
                      <div class="form-check form-check-inline">
                        <input
                          :id="`optionTemporary_${index}_true`"
                          v-model="temp_search[index].optionTemporary"
                          class="form-check-input"
                          type="radio"
                          :name="`optionTemporary_${index}`"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          :for="`optionTemporary_${index}_true`"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          :id="`optionTemporary_${index}_false`"
                          v-model="temp_search[index].optionTemporary"
                          class="form-check-input"
                          type="radio"
                          :name="`optionTemporary_${index}`"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          :for="`optionTemporary_${index}_false`"
                        >否</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-center align-items-center">
                <a
                  href="#"
                  class="d-flex btn btn_primary px-2"
                  @click.prevent="add_category()"
                >
                  新增歸屬
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label>門市狀態</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="store_status_true"
                      v-model="store.status"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="store_status"
                      :value="true"
                      data-vv-scope="detail"
                    >
                    <label
                      class="form-check-label"
                      for="store_status_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="store_status_false"
                      v-model="store.status"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="store_status"
                      :value="false"
                      data-vv-scope="detail"
                    >
                    <label
                      class="form-check-label"
                      for="store_status_false"
                    >否</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_storeCode">門市代碼</label>
                  <input
                    id="store_storeCode"
                    v-model="store.storeCode"
                    v-validate="'required'"
                    type="text"
                    name="門市代碼"
                    class="form-control form_input flex-fill"
                    placeholder="門市代碼"
                    :class="{'is-invalid': errors.has('detail.門市代碼')}"
                    data-vv-scope="detail"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_storeName">名稱</label>
                  <input
                    id="store_storeName"
                    v-model="store.storeName"
                    v-validate="'required'"
                    type="text"
                    name="名稱"
                    class="form-control form_input flex-fill"
                    placeholder="名稱"
                    :class="{'is-invalid': errors.has('detail.名稱')}"
                    data-vv-scope="detail"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_telnum">聯絡電話</label>
                  <input
                    id="store_telnum"
                    v-model="store.telnum"
                    v-validate="'required'"
                    type="tel"
                    name="聯絡電話"
                    class="form-control form_input flex-fill"
                    placeholder="連絡電話"
                    :class="{'is-invalid': errors.has('detail.聯絡電話')}"
                    data-vv-scope="detail"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_area">區域</label>
                  <select
                    id="store_area"
                    v-model="store.area"
                    v-validate="'required'"
                    name="區域"
                    class="form-control form_input flex-fill"
                    :class="{'is-invalid': errors.has('detail.區域')}"
                    data-vv-scope="detail"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      區域
                    </option>
                    <option
                      v-for="item in option.storeArea"
                      :key="item.areaCode"
                      :value="item.areaName"
                    >
                      {{ item.areaName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_address">地址</label>
                  <input
                    id="store_address"
                    v-model="store.address"
                    v-validate="'required'"
                    type="text"
                    name="地址"
                    class="form-control form_input flex-fill"
                    placeholder="地址"
                    :class="{'is-invalid': errors.has('detail.地址')}"
                    data-vv-scope="detail"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_officeHour">營業時段</label>
                  <input
                    id="store_officeHour"
                    v-model="store.officeHour"
                    v-validate="'required'"
                    type="text"
                    name="營業時段"
                    class="form-control form_input flex-fill"
                    placeholder="營業時段"
                    :class="{'is-invalid': errors.has('detail.營業時段')}"
                    data-vv-scope="detail"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_officeHourPeriod">特殊時段</label>
                  <input
                    id="store_officeHourPeriod"
                    v-model="store.officeHourPeriod"
                    type="text"
                    name="特殊時段"
                    class="form-control form_input flex-fill"
                    placeholder="特殊時段"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_googleMap">MAP連結</label>
                  <input
                    id="store_googleMap"
                    v-model="store.googleMap"
                    type="text"
                    name="MAP連結"
                    class="form-control form_input flex-fill"
                    placeholder="MAP連結"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="store_traffic">交通指引</label>
                  <input
                    id="store_traffic"
                    v-model="store.traffic"
                    type="text"
                    name="交通指引"
                    class="form-control form_input flex-fill"
                    placeholder="交通指引"
                  >
                </div>
              </div>
            </div>
            <div
              v-if="edit_mode && !edit_section_new"
              class="row"
            >
              <div class="col">
                <div class="form-group form_input_image">
                  <label for="">吉品(435x275)</label>
                  <div class="input_custom">
                    <span>加入圖片</span>
                  </div>
                  <input
                    ref="files"
                    type="file"
                    data-name="imageGping"
                    class="form-control"
                    accept="image/jpeg"
                    @change="readURL($event,$event.target)"
                  >
                  <div class="display_image">
                    <!-- <img id="imageListGping" :src="store.images.imageListGping + '?' + `${Math.random()}`" class="img-fluid" /> -->
                    <img
                      id="imageGping"
                      :src="store_images.imageGping"
                      class="img-fluid"
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group form_input_image">
                  <label for="">無二(1400x890)</label>
                  <div class="input_custom">
                    <span>加入圖片</span>
                  </div>
                  <input
                    ref="files"
                    type="file"
                    data-name="imageWuer"
                    class="form-control"
                    accept="image/jpeg"
                    @change="readURL($event,$event.target)"
                  >
                  <div class="display_image">
                    <!-- <img id="imageWuer" :src="store.images.imageListWuer + '?' + `${Math.random()}`" class="img-fluid" /> -->
                    <img
                      id="imageWuer"
                      :src="store_images.imageWuer"
                      class="img-fluid"
                    >
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group form_input_image">
                  <label for="">福貴糕</label>
                  <div class="input_custom">
                    <span>加入圖片</span>
                  </div>
                  <input
                    ref="files"
                    type="file"
                    data-name="imageFugui"
                    class="form-control"
                    accept="image/jpeg"
                    @change="readURL($event,$event.target)"
                  >
                  <div class="display_image">
                    <!-- <img id="imageFugui" :src="store.images.imageFugui + '?' + `${Math.random()}`" class="img-fluid" /> -->
                    <img
                      id="imageFugui"
                      :src="store_images.imageFugui"
                      class="img-fluid"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <a
                href="#"
                class="btn btn_primary rounded-0"
                @click.prevent="edit_store(edit_section_new)"
              >{{ edit_section_new === true ? '建立' : '更新' }}</a>
              <a
                href="#"
                class="btn btn_default rounded-0"
                @click.prevent="edit_mode = false, edit_section_new = false, init_validate()"
              >關閉</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_STORE_CATEGORY,
  API_GET_STORE_LIST,
  API_GET_STORE_DETAIL,
  API_UPDATE_STORE,
  API_CREATE_STORE,
  API_DELETE_STORE,
  API_UPDATE_STORE_ORDER,
  API_UPLOAD_STOREIMAGE,
} from '@/plugins/api';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      now_page: 'store',
      store_category: [], /* 門市分類 */
      store_list: [], /* 門市列表 */
      search: {
        system: '',
        category_code: '',
      },
      store: {
        images: {},
        area: '',
        status: false,
      }, /* 門市細節 */
      edit_mode: false, /* 編輯模式 */
      edit_section_new: false, /* 編輯的區塊是否為新 */
      temp_search: [
        /* 建立、 編輯用 */
        {
          system: '',
          categorySubCode: '',
          categoryType: '',
          dtStart: '',
          dtEnd: '',
          optionTemporary: false,
        },
      ],
      temp_category_data: [
        {
          category: [],
        },
      ],
      pagination: {
        /* 分頁設定 */
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      },
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    filter_store_category() {
      const vm = this;
      vm.search.category_code = '';
      const category = [];
      vm.store_category.forEach((item) => {
        if (item.categoryName !== '台灣據點') {
          item.items.forEach((item) => {
            category.push(item);
          });
        } else {
          item.items.forEach((item) => {
            category.push(item);
          });
        }
      });
      return category;
    },
    filter_Data: {
      get() {
        const vm = this;
        /* 先給空陣列，避免 store_list 還沒拿到資料出錯 */
        let storeData = [];
        storeData = vm.store_list.slice();
        const dataLen = storeData.length;
        const nowPage = vm.pagination.current_page;
        vm.pagination.total_pages = Math.ceil(
          dataLen / vm.pagination.filter_page_size,
        );
        vm.pagination.filter_range_page = [];
        for (let index = 1; index <= vm.pagination.total_pages; index += 1) {
          if (index >= (nowPage - vm.pagination.filter_range) && index <= (nowPage + vm.pagination.filter_range)) {
            vm.pagination.filter_range_page.push({
              index,
            });
          }
        }
        if (nowPage > 1) {
          vm.pagination.has_pre = true;
        } else {
          vm.pagination.has_pre = false;
        }
        if (nowPage === vm.pagination.total_pages) {
          vm.pagination.has_next = false;
        } else {
          vm.pagination.has_next = true;
        }
        return storeData.filter(
          (item, index) => index < nowPage * vm.pagination.filter_page_size
            && index >= (nowPage - 1) * vm.pagination.filter_page_size,
        );
      },
      set() {},
    },
    store_images() {
      const vm = this;
      let data = {
        imageGping: `${process.env.VUE_APP_API_PATH}/images/store/${vm.store.storeCode}/gping.jpg`,
        imageWuer: `${process.env.VUE_APP_API_PATH}/images/store/${vm.store.storeCode}/wuer.jpg`,
        imageFugui: `${process.env.VUE_APP_API_PATH}/images/store/${vm.store.storeCode}/fugui.jpg`,
      };
      const storeLength = Object.keys(vm.store.images).length;
      if (storeLength !== 0) {
        data = Object.assign(vm.store.images);
      }
      return data;
    },
  },
  watch: {
    'pagination.filter_page_size': {
      handler: async function initCurrentPage(newValue, oldValue) {
        const vm = this;
        vm.pagination.current_page = 1;
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
    /* 初始化 pagination */
    async init_pagination() {
      const vm = this;
      vm.pagination = {
        /* 分頁設定 */
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      };
    },
    /* 初始化 store 內容 */
    async init_store() {
      const vm = this;
      vm.store = {
        images: {},
        area: '',
        status: false,
      };
      vm.edit_mode = false;
      vm.edit_section_new = false;
      vm.temp_search = [
        {
          system: '',
          categorySubCode: '',
          categoryType: '',
          dtStart: '',
          dtEnd: '',
          optionTemporary: false,
        },
      ];
      vm.temp_category_data = [
        {
          category: [],
        },
      ];
      await vm.init_validate();
    },
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* 切換頁數 */
    change_page(page = 1) {
      const vm = this;
      vm.pagination.current_page = page;
    },
    /* 取得門市分類 */
    async get_store_category() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_STORE_CATEGORY(vm.search.system);
      const res = response.data;
      if (res.code === '0000') {
        vm.store_category = res.data;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得門市列表 */
    async get_store_list() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      if (vm.search.category_code === '') {
        await vm.$store.dispatch('page_load', false);
        return;
      }
      const response = await API_GET_STORE_LIST(vm.search.category_code);
      const res = response.data;
      if (res.code === '0000') {
        vm.store_list = res.data;
        vm.store_list.forEach((item, index) => {
          item.index = index + 1;
        });
        await vm.init_pagination();
        await vm.init_store();
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得門市細節 */
    async get_store_detail(storeCode) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_STORE_DETAIL(storeCode);
      const res = response.data;
      if (res.code === '0000') {
        vm.store = res.data;
        vm.edit_mode = true;
        vm.temp_search = [];
        vm.temp_category_data = [];
        for (let index = 0; index < vm.store.category.length; index += 1) {
          const categoryData = {
            category: [],
          };
          vm.temp_category_data.push(categoryData);
          const response = await API_GET_STORE_CATEGORY(vm.store.category[index].categorySubCode.slice(0, 2));
          const res = response.data;
          const category = [];
          res.data.forEach((item) => {
            if (item.categoryName !== '台灣據點') {
              item.items.forEach((item) => {
                category.push(item);
              });
            } else {
              item.items.forEach((item) => {
                category.push(item);
              });
            }
          });
          vm.temp_category_data[index].category = category;
          const searchData = {
            categorySubCode: vm.store.category[index].categorySubCode,
            system: vm.store.category[index].categorySubCode.slice(0, 2),
            categoryType: vm.store.category[index].categoryType,
            dtStart: vm.store.category[index].dtStart,
            dtEnd: vm.store.category[index].dtEnd,
            optionTemporary: vm.store.category[index].optionTemporary,
          };
          vm.temp_search.push(searchData);
        }
        await vm.init_validate();
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得 store 分類列表 => 多組分類時專用 */
    async get_temp_category(value, index) {
      const vm = this;
      const category = [];
      const response = await API_GET_STORE_CATEGORY(value);
      const res = response.data;
      res.data.forEach((item) => {
        if (item.categoryName !== '台灣據點') {
          item.items.forEach((item) => {
            category.push(item);
          });
        } else {
          item.items.forEach((item) => {
            category.push(item);
          });
        }
      });
      vm.temp_category_data[index].category = category;
    },
    /* 新增門市分類 */
    async add_category() {
      const vm = this;
      const category = {
        system: '',
        categorySubCode: '',
        areaName: '',
        dtStart: '',
        dtEnd: '',
        optionTemporary: false,
      };
      const categoryData = {
        category: [],
      };
      vm.temp_search.push(category);
      vm.temp_category_data.push(categoryData);
    },
    /* 刪除門市分類 */
    async delete_category(index) {
      const vm = this;
      vm.temp_search.splice(index, 1);
      vm.temp_category_data.splice(index, 1);
    },
    /* 檢測 建立/更新 門市資料 */
    async edit_store(isnew) {
      const vm = this;
      switch (isnew) {
        case true:
          await vm.create_store();
          break;
        case false:
          await vm.update_store();
          break;
        default:
          break;
      }
    },
    /* 建立門市 */
    async create_store() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        const categoryCodelist = [];
        vm.temp_search.forEach((item) => {
          categoryCodelist.push({
            categorySubCode: item.categorySubCode,
            type: item.categoryType,
            dtStart: item.dtStart,
            dtEnd: item.dtEnd,
            optionTemporary: item.optionTemporary,
          });
        });
        const data = {
          status: vm.store.status,
          storeCode: vm.store.storeCode || '',
          storeName: vm.store.storeName || '',
          area: vm.store.area || '',
          telnum: vm.store.telnum || '',
          address: vm.store.address || '',
          officeHourPeriod: vm.store.officeHourPeriod || '',
          officeHour: vm.store.officeHour || '',
          traffic: vm.store.traffic || '',
          googleMap: vm.store.googleMap || '',
          categorySub: categoryCodelist,
          label: vm.store.label || [],
        };
        const response = await API_CREATE_STORE(data);
        const res = response.data;
        if (res.code === '0000') {
          await vm.upload_picture(res.data.storeCode);
          vm.edit_mode = false;
          vm.edit_section_new = false;
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_store_list();
          await vm.init_store();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新門市 */
    async update_store() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        const categoryCodelist = [];
        vm.temp_search.forEach((item) => {
          categoryCodelist.push({
            categorySubCode: item.categorySubCode,
            type: item.categoryType,
            dtStart: item.dtStart,
            dtEnd: item.dtEnd,
            optionTemporary: item.optionTemporary,
          });
        });
        const data = {
          status: vm.store.status,
          storeCode: vm.store.storeCode || '',
          storeName: vm.store.storeName || '',
          area: vm.store.area || '',
          telnum: vm.store.telnum || '',
          address: vm.store.address || '',
          officeHourPeriod: vm.store.officeHourPeriod || '',
          officeHour: vm.store.officeHour || '',
          traffic: vm.store.traffic || '',
          googleMap: vm.store.googleMap || '',
          categorySub: categoryCodelist,
          label: vm.store.label || [],
        };
        const response = await API_UPDATE_STORE(data);
        const res = response.data;
        if (res.code === '0000') {
          await vm.upload_picture(res.data.storeCode);
          vm.edit_mode = false;
          vm.edit_section_new = false;
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_store_list();
          await vm.init_store();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 刪除門市 */
    async delete_store(storeCode) {
      const vm = this;
      const isConfirm = await vm.$swal({
        title: '確定刪除此門市?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '刪除',
      });
      if (isConfirm.isConfirmed) {
        await vm.$store.dispatch('page_load', true);
        const response = await API_DELETE_STORE(storeCode);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            timer: 1000,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_store_list();
        }
        await vm.$store.dispatch('page_load', false);
      }
    },
    /* 預覽圖片 */
    async readURL(e, input) {
      const targetId = e.target.files[0].name;
      if (input.files.length > 0) {
        input.files.forEach((item) => {
          const reader = new FileReader();
          reader.onload = function onload(eDetail) {
            const img = $(e.target).siblings('.display_image').find('img');
            $(img).attr('src', eDetail.target.result);
            // $(e.target).siblings('.input_custom').find('span').text(targetId);
          };
          reader.readAsDataURL(item);
        });
      }
    },
    /* 上傳門市圖片 */
    async upload_picture(storeCode) {
      const vm = this;
      const storeImageData = Array.from(
        document.querySelectorAll('.form_input_image input'),
      );
      const data = new FormData();
      data.append('storeCode', storeCode);
      storeImageData.forEach((image) => {
        data.append(image.dataset.name, image.files[0]);
      });
      await API_UPLOAD_STOREIMAGE(data);
    },
    /* 門市排序 */
    async update_order({ moved }) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = {
        categorySubCode: vm.search.category_code,
        number: moved.newIndex + 1,
        storeCode: moved.element.storeCode,
      };
      const response = await API_UPDATE_STORE_ORDER(data);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功!',
          text: res.text,
          timer: 1000,
          confirmButtonText: '關閉視窗',
        });
        await vm.get_store_list();
      }
      await vm.$store.dispatch('page_load', false);
    },
  },
};
</script>
